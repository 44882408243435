<template>
  <div class="all_main_wrap">
    <!-- 头部 -->
    <div class="all_header_box">
      <div class="sider_left">
        <!-- 面包屑 -->
        <span class="title">
          <router-link
            class="crumbs_item"
            tag="a"
            to="/admin/Examination/ExamSeting"
            >新建考试 /
          </router-link>
          <span class="crumbs_item crumbs_last"
            >{{ configId ? "编辑" : "新建" }}模拟考试</span
          >
        </span>
      </div>
    </div>
    <a-form
      :form="form"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 15 }"
      @submit="handleSubmit"
    >
      <a-row style="margin-top: 20px">
        <a-col :span="11" offset="1">
          <a-form-item label="模拟考试名称">
            <a-input
              autoComplete="off"
              placeholder="请输入考试名称"
              v-decorator="[
                'name',
                { rules: [{ required: true, message: '请输入考试名称' }] },
              ]"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="11" offset="1">
          <a-form-item label="移动端列表图">
            <a-upload
              v-decorator="[
                'appPicture',
                { rules: [{ required: true, message: '请上传图片' }] },
              ]"
              name="appPicture"
              list-type="picture-card"
              :showUploadList="false"
              accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
              :beforeUpload="beforeUpload"
              :customRequest="customRequest"
            >
              <img
                class="uploadImg"
                v-if="pictureList.appPicture"
                :src="pictureList.appPicture"
                alt="avatar"
              />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
            <p class="tips">建议尺寸：750*750 px ，最多可传一张</p>
          </a-form-item>
        </a-col>
        <a-col :span="11" offset="1">
          <a-form-item label="pc端列表图">
            <a-upload
              v-decorator="[
                'webPicture',
                { rules: [{ required: true, message: '请上传图片' }] },
              ]"
              name="webPicture"
              list-type="picture-card"
              :showUploadList="false"
              accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
              :beforeUpload="beforeUpload"
              :customRequest="customRequest"
            >
              <img
                class="uploadImg"
                v-if="pictureList.webPicture"
                :src="pictureList.webPicture"
                alt="avatar"
              />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
            <p class="tips">建议尺寸：750*530 px ，最多可传一张</p>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row v-if="false">
        <a-col :span="11" offset="1">
          <a-form-item label="移动端详情">
            <a-upload
              v-decorator="[
                'appDetailPicture',
                { rules: [{ required: true, message: '请上传图片' }] },
              ]"
              name="appDetailPicture"
              list-type="picture-card"
              :showUploadList="false"
              accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
              :beforeUpload="beforeUpload"
              :customRequest="customRequest"
            >
              <img
                class="uploadImg"
                v-if="pictureList.appDetailPicture"
                :src="pictureList.appDetailPicture"
                alt="avatar"
              />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
            <p class="tips">建议尺寸：300*260 px ，最多可传一张</p>
          </a-form-item>
        </a-col>
        <a-col :span="11" offset="1">
          <a-form-item label="pc端详情">
            <a-upload
              v-decorator="[
                'webDetailPicture',
                { rules: [{ required: true, message: '请上传图片' }] },
              ]"
              name="webDetailPicture"
              list-type="picture-card"
              :showUploadList="false"
              accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
              :beforeUpload="beforeUpload"
              :customRequest="customRequest"
            >
              <img
                class="uploadImg"
                v-if="pictureList.webDetailPicture"
                :src="pictureList.webDetailPicture"
                alt="avatar"
              />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
            <p class="tips">建议尺寸：750*750 px ，最多可传一张</p>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row v-if="false">
        <a-col :span="11" offset="1">
          <a-form-item label="移动端商品主图">
            <a-upload
              v-decorator="[
                'editorPicture',
                { rules: [{ required: true, message: '请上传图片' }] },
              ]"
              name="editorPicture"
              list-type="picture-card"
              :showUploadList="false"
              accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
              :beforeUpload="beforeUpload"
              :customRequest="customRequest"
            >
              <img
                class="uploadImg"
                v-if="pictureList.editorPicture"
                :src="pictureList.editorPicture"
                alt="avatar"
              />
              <div v-else>
                <a-icon :type="loading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
            <!-- <p class="tips">建议尺寸：360*313 px ，最多可传一张</p> -->
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="11" offset="1">
          <a-form-item label="考试时长">
            <a-time-picker
              style="width: 100%"
              :default-open-value="moment('00:00:00', 'HH:mm:ss')"
              valueFormat="HH:mm:ss"
              v-decorator="[
                'duration',
                { rules: [{ required: true, message: '请选择时间' }] },
              ]"
            >
              <a-icon slot="suffixIcon" type="smile" />
            </a-time-picker>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="11" offset="1">
          <a-form-item label="关联课程" required>
            <p class="selectCourse" @click="selectCoursevisible = true">
              选择课程
            </p>
            <div class="selectitem" v-show="enterselectedcourseList.length" v-for="(item,index) in enterselectedcourseList" :key="item.courseId">
              <p class="name">{{ item.courseName }}</p>
              <p
                class="del"
                @click="deleteItem(index)"
              >
                删除
              </p>
            </div>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="11" offset="1">
          <a-form-item label="关联试卷" required>
            <p class="selectCourse" @click="selectPapervisible = true">
              选择试卷
            </p>
            <div class="selectitem" v-show="paperName">
              <p class="name">{{ paperName }}</p>
              <p
                class="del"
                @click="(paperName = ''), (selectedpaperRowKeys = [])"
              >
                删除
              </p>
            </div>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row style="margin-top: 20px">
        <a-col :span="11" offset="1">
          <a-form-item label="合格分数">
            <p class="gardeTitle" v-show="paperName">（该试卷总分{{totalScore}}分）</p>
            <a-input-number
              style="width: 100%"
              :min="0"
              placeholder="请输入考试及格分数"
              v-decorator="[
                'passingScore',
                { rules: [{ required: true, message: '请输入考试及格分数' }] },
              ]"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="11" offset="1">
          <a-form-item label="所属商品类别" required>
            <!-- <a-cascader
              :options="
                JSON.parse(JSON.stringify(formatData(shopTypeoptions))) || []
              "
              v-decorator="[
                'goodsType',
                { rules: [{ required: true, message: '请选择商品类别' }] },
              ]"
              :fieldNames="{ label: 'name', value: 'id', children: 'children' }"
              placeholder="请选择"
              @change="changeShopType"
            /> -->
            <a-tree-select
              v-decorator="[
                'goodsType',
                { rules: [{ required: true, message: '请选择商品类别' }] },
              ]"
              class="right"
              :tree-data="JSON.parse(JSON.stringify(formatData(shopTypeoptions))) || []"
              tree-checkable
              search-placeholder="Please select"
              placeholder="请选择商品类别"
              :replaceFields="replaceFields"
              @change="changeShopType"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row style="margin-top: 20px">
        <a-col :span="11" offset="1">
          <a-form-item label="商品价格设置">
            <a-row>
              <a-col :span="14">
                <a-form-item
                  :label-col="{ span: 12 }"
                  :wrapper-col="{ span: 12 }"
                  label="市场价（原价）"
                >
                  <a-input-number
                    :min="0"
                    style="width: 100px"
                    placeholder=""
                    v-decorator="[
                      'marketPrice',
                      {
                        rules: [
                          { required: true, message: '请输入市场价（原价）' },
                        ],
                      },
                    ]"
                  />
                  元
                </a-form-item>
              </a-col>
              <a-col :span="10">
                <a-checkbox :checked="!!isMarket" @change="changeOldPrice">
                  是否在终端显示该价格
                </a-checkbox>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="14">
                <a-form-item
                  :label-col="{ span: 12 }"
                  :wrapper-col="{ span: 12 }"
                  label="优惠价"
                >
                  <a-input-number
                    :min="0"
                    @blur="priceBlur"
                    style="width: 100px"
                    placeholder=""
                    v-decorator="[
                      'specialPrice',
                      {
                        rules: [{ required: true, message: '请输入优惠价' }],
                      },
                    ]"
                  />
                  元
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :span="10">
              <p style="font-size: 12px; text-align: center; color: red">
                该价格为用户支付价格，不得设置大于原价
              </p>
              <!-- <a-checkbox :checked="!!isSpecial" @change="changeNewPrice">
                是否在终端显示该价格
              </a-checkbox> -->
            </a-row>
          </a-form-item>
        </a-col>
      </a-row>
      <!-- <a-row style="margin-top: 20px">
        <a-col :span="11" offset="1">
          <a-form-item label="机构购买设置" required>
            <a-row>
              <a-radio-group
                v-decorator="[
                  'isInstituBuy',
                  {
                    rules: [
                      { required: true, message: '请设置是否允许机构购买' },
                    ],
                  },
                ]"
              >
                <a-col :span="12">
                  <a-radio :value="0"> 不允许机构购买 </a-radio>
                </a-col>
                <a-col :span="12">
                  <a-radio :value="1"> 允许机构购买 </a-radio>
                </a-col>
              </a-radio-group>
            </a-row>
            <a-row
              v-if="form.getFieldsValue(['isInstituBuy']).isInstituBuy == 1"
            >
              <a-col :span="24">
                <a-form-item
                  :label-col="{ span: 4 }"
                  :wrapper-col="{ span: 20 }"
                  label="机构价"
                >
                  <a-input-number
                    :min="0"
                    style="width: 80px"
                    placeholder=""
                    v-decorator="[
                      'instituPrice',
                      { rules: [{ required: true, message: '请输入机构价' }] },
                    ]"
                  />
                  元，须达到
                  <a-input-number
                    :min="0"
                    style="width: 50px"
                    placeholder=""
                    v-decorator="['instituBuyNum']"
                  />
                  人购买方可使用机构价
                </a-form-item>
              </a-col>
            </a-row>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="7">
          <a-form-item
            :label-col="{ span: 18 }"
            :wrapper-col="{ span: 6 }"
            label="机构可否以兑换券形式购买"
          >
            <a-radio-group
              v-decorator="[
                'isCoupon',
                {
                  rules: [
                    {
                      required: true,
                      message: '请设置机构可否以兑换券形式购买',
                    },
                  ],
                },
              ]"
            >
              <a-col :span="12">
                <a-radio :value="0"> 否 </a-radio>
              </a-col>
              <a-col :span="12">
                <a-radio :value="1"> 是 </a-radio>
              </a-col>
            </a-radio-group>
          </a-form-item>
        </a-col>
      </a-row> -->
        <!-- <a-col :span="4" v-if="form.getFieldsValue(['isCoupon']).isCoupon == 1">
          <a-select
            v-decorator="[
              'couponId',
              { rules: [{ required: true, message: '请选择兑换券' }] },
            ]"
            placeholder="请选择兑换券"
            @change="selectExchange"
          >
            <a-select-option value="male"> male </a-select-option>
            <a-select-option value="female"> female </a-select-option>
          </a-select>
        </a-col> -->
      <a-row style="margin-top: 20px">
        <a-col :span="11" offset="1">
          <a-form-item label="考试次数设置" required>
            <a-row>
              <a-radio-group
                v-decorator="[
                  'isExam',
                  {
                    rules: [
                      { required: true, message: '请设置考试次数' },
                    ],
                  },
                ]"
              >
                <a-col :span="12">
                  <a-radio :value="0"> 不限次数 </a-radio>
                </a-col>
                <a-col :span="12">
                  <a-radio :value="1">
                    可参加
                    <a-input-number
                      :min="0"
                      style="width: 50px"
                      placeholder=""
                      v-decorator="['examNum']"
                    />
                    次考试
                  </a-radio>
                </a-col>
              </a-radio-group>
            </a-row>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="row">
        <a-col offset="1" span="13" class="all_required">
          模考简介（pc端）：
        </a-col>
      </a-row>
      <a-row class="row" style="margin-top: 20px">
        <a-col offset="2" span="16">
          <UE
            editorId="introduceEditor1"
            :initContent="text.webDesc"
            @input="getPcContentwebDesc"
          />
        </a-col>
      </a-row>
      <a-row class="row" style="margin-top: 20px">
        <a-col offset="1" span="13" class="all_required">
          模考简介（移动端）：
        </a-col>
      </a-row>
      <a-row class="row" style="margin-top: 20px">
        <a-col offset="2" span="16">
          <!-- <UE
            editorId="introduceEditor2"
            :initContent="text.appDesc"
            @input="getPcContentappDesc"
          /> -->
           <a-textarea
            placeholder="请设置模考简介"
            :rows="10"
            style="resize: none"
            v-model="text.appDesc"
          />
        </a-col>
      </a-row>
      <a-row class="row" style="margin-top: 20px">
        <a-col offset="1" span="13"> 详情介绍（移动端）： </a-col>
      </a-row>
      <a-row class="row" style="margin-top: 20px">
        <a-col offset="2" span="16">
          <UE
            editorId="introduceEditor3"
            :initContent="text.appGoodsDesc"
            @input="getPcContentappGoodsDesc"
          />
        </a-col>
      </a-row>
      <a-row class="row" style="margin-top: 20px">
        <a-col offset="1" span="13"> 详情介绍（pc端）： </a-col>
      </a-row>
      <a-row class="row" style="margin-top: 20px">
        <a-col offset="2" span="16">
          <UE
            editorId="introduceEditor4"
            :initContent="text.webGoodsDesc"
            @input="getPcContentwebGoodsDesc"
          />
        </a-col>
      </a-row>
      <a-row style="margin-top: 20px">
        <a-col :span="23" offset="1" class="addQues">
          <a-form-item
            :label-col="{ span: 24 }"
            :wrapper-col="{ span: 17, offset: 1 }"
            label="常见问题"
          >
            <a-button
              type="primary"
              class="addQuesbtn"
              @click="addQuestionvisible = true"
            >
              新建问题
            </a-button>
            <a-table
              class="table-template"
              :rowKey="(item, index) => index"
              :columns="columns"
              :data-source="problemJson"
              @change="onPage"
              :pagination="{
                total: total,
                current: pageNumber,
                defaultPageSize: pageSize,
                showSizeChanger: true,
                showTotal: function (total, range) {
                  return `共${total}条`;
                },
              }"
            >
              <template slot="index" slot-scope="item, row, i">
                <div style="text-align: center">
                  {{ (pageNumber - 1) * pageSize + i + 1 }}
                </div>
              </template>

              <template slot="operation" slot-scope="text, record, index">
                <div class="btn_router_link">
                  <router-link to @click.native="editQuestionItem(index)"
                    >编辑</router-link
                  >
                  <span>|</span>
                  <router-link to @click.native="delQuestion(index)"
                    >删除</router-link
                  >
                  <span v-show="(pageNumber - 1) * pageSize + index !== 0"
                    >|</span
                  >
                  <router-link
                    to
                    v-show="(pageNumber - 1) * pageSize + index !== 0"
                    @click.native="questionUp(index)"
                    >上移</router-link
                  >
                  <span
                    v-show="
                      (pageNumber - 1) * pageSize + index !==
                      problemJson.length - 1
                    "
                    >|</span
                  >
                  <router-link
                    to
                    v-show="
                      (pageNumber - 1) * pageSize + index !==
                      problemJson.length - 1
                    "
                    @click.native="questionDown(index)"
                    >下移</router-link
                  >
                </div>
              </template>
            </a-table>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row style="margin-top: 20px">
        <a-col :span="11" offset="1">
          <a-form-item label="上架时间" required>
            <a-radio-group
              v-decorator="[
                'isShelf',
                { rules: [{ required: true, message: '请选择上架时间' }] },
              ]"
            >
              <a-row style="line-height: 40px">
                <a-col :span="12">
                  <a-radio :value="1"> 立即上架 </a-radio>
                </a-col>
              </a-row>
              <a-row style="line-height: 40px">
                <a-col :span="12">
                  <a-radio :value="0"> 暂不上架 </a-radio>
                </a-col>
              </a-row>
              <a-row style="line-height: 40px">
                <a-col :span="12">
                  <a-radio :value="2">
                    设置上架时间
                    <a-date-picker
                      v-if="form.getFieldsValue(['isShelf']).isShelf == 2"
                      :disabled-date="disabledDate"
                      :disabledTime="disabledDateTime"
                      valueFormat="YYYY-MM-DD"
                      placeholder="请设置上架时间"
                      v-decorator="['shelfTime']"
                      @change="changelineTime"
                      @ok="lineTimeOk"
                    />
                  </a-radio>
                </a-col>
              </a-row>
            </a-radio-group>
          </a-form-item>
        </a-col>
      </a-row>
      <a-form-item :wrapper-col="{ span: 12, offset: 1 }">
        <a-button type="primary" html-type="submit" :loading="buttonloading"> 确认 </a-button>
        <a-button
          class="all_boder_btn"
          style="margin-left: 20px"
          @click="$router.go(-1)"
        >
          取消
        </a-button>
      </a-form-item>
    </a-form>
    <!-- 新建问题 -->
    <a-modal
      v-if="addQuestionvisible"
      :title="questionIndex !== '' ? '编辑问题' : '新建问题'"
      okText="确认"
      :visible="addQuestionvisible"
      :confirm-loading="confirmLoading"
      @ok="addQuestionOk"
      @cancel="(addQuestionvisible = false), (questionIndex = ''),(questionform.name = ''),(questionform.context = '')"
    >
      <a-form-model ref="ruleForm" :model="questionform" :rules="questionrules">
        <a-form-model-item
          ref="question"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          label="问题"
          prop="name"
        >
          <a-input
            placeholder="请输入问题"
            v-model="questionform.name"
            @blur="
              () => {
                $refs.question.onFieldBlur();
              }
            "
          />
        </a-form-model-item>
        <a-form-model-item
          ref="answer"
          label="回答"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 18 }"
          prop="context"
        >
          <a-textarea
            v-model="questionform.context"
            :auto-size="{ minRows: 3, maxRows: 5 }"
            :maxLength="1000"
            @blur="
              () => {
                $refs.answer.onFieldBlur();
              }
            "
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <!-- 选择试卷 -->
    <a-modal
      title="选择试卷"
      okText="确认"
      :visible="selectPapervisible"
      :confirm-loading="confirmLoading"
      @ok="selectPaper"
      @cancel="(selectPapervisible = false), (selectedpaperRowKeys = []),(paper.title = ''),(paper.pageNumber = 1),(getManageList)"
    >
      <div class="findPaper">
        <a-input placeholder="请输入关键字" v-model="paper.title" />
        <a-button type="primary" @click="getManageList">搜索</a-button>
      </div>
      <a-table
        class="table-template"
        :rowKey="(item) => item.paperId"
        :columns="papercolumns"
        :data-source="paperList"
        @change="onPaperPage"
        :row-selection="{
          type:'radio',
          selectedRowKeys: selectedpaperRowKeys,
          onChange: selectpaperItem,
          onSelectAll: selectAllpaper,
          columnTitle: '选择',
        }"
        :pagination="{
          total: paper.total,
          current: paper.pageNumber,
          defaultPageSize: paper.pageSize,
          showSizeChanger: true,
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (paper.pageNumber - 1) * 10 + i + 1 }}
          </div>
        </template>
      </a-table>
    </a-modal>
    <!-- 选择课程 -->
    <a-modal
      title="选择课程"
      okText="确认"
      :visible="selectCoursevisible"
      :confirm-loading="confirmLoading"
      @ok="selectCourse"
      @cancel="cancelSelectCouse"
    >
      <div class="findPaper">
        <a-input placeholder="请输入关键字" v-model="course.title" />
        <a-button type="primary" @click="getCourseList">搜索</a-button>
      </div>
      <a-table
        class="table-template"
        :rowKey="(item) => item.courseId"
        :columns="coursecolumns"
        :data-source="courseList"
        @change="onCoursePage"
        :row-selection="{
          selectedRowKeys: selectedcourseRowKeys,
          onChange: selectcourseItem,
          // onSelectAll: selectAllpaper,
          columnTitle: '选择',
        }"
        :pagination="{
          total: course.total,
          current: course.pageNumber,
          defaultPageSize: course.pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (course.pageNumber - 1) * 10 + i + 1 }}
          </div>
        </template>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "问题",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "回答",
    align: "center",
    dataIndex: "context",
  },
  {
    title: "操作",
    align: "center",
    width: "200px",
    scopedSlots: { customRender: "operation" },
  },
];
const papercolumns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "试卷名称",
    align: "center",
    dataIndex: "paperName",
  },
];
const coursecolumns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "课程名称",
    align: "center",
    dataIndex: "courseName",
  },
];
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    // console.log(
    //   `selectedRowKeys: ${selectedRowKeys}`,
    //   "selectedRows: ",
    //   selectedRows
    // );
  },
  onSelect: (record, selected, selectedRows) => {
    // console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    // console.log(selected, selectedRows);
  },
};

const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};
import moment from "moment";
import UE from "@/components/UE.vue";
export default {
  // 可用组件的哈希表
  components: { UE },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      buttonloading:false,
      courseName: "", // 课程名称
      enterselectedcourseList: [], // 已确认的课程列表
      selectedcourseList: [], // 已选课程列表
      paperName: "", // 选中的试卷名称
      totalScore: "", // 选中的试卷的总分
      columns, // 表头
      papercolumns,
      coursecolumns,
      shopTypeoptions: undefined, // 商品分类列表
      tableData: [], // 列表数据
      paperList: [], // 试卷列表
      courseList: [], // 课程列表
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      selectedpaperRowKeys: [],
      selectedcourseRowKeys: [],
      rowSelection,
      goodsCategory: "", // 商品类别
      goodsClassifi: "", // 商品分类
      problemJson: [], // 常见问题
      questionIndex: "", // 当前操作下标
      configId: "",
      // 富文本
      text: {
        webDesc: "", //	web模拟考试说明
        webGoodsDesc: "", //	web商品详情介绍
        appDesc: "", //	app模拟考试说明
        appGoodsDesc: "", //	app商品详情介绍
      },
      // 替换字段
      replaceFields: {
        title: "name",
        value: "id",
        key: "id",
      },
      paper: {
        title: "", // 关键字
        total: 0, // 总数量
        pageNumber: 1, //页码
        pageSize: 10, // 页数
      },
      course: {
        title: "", // 关键字
        total: 0, // 总数量
        pageNumber: 1, //页码
        pageSize: 10, // 页数
      },
      pictureList: {
        appPicture: "",
      },
      isMarket: 0, // 市场价是否再终端显示 1.是 0.否
      isSpecial: 0, // 优惠价是否再终端显示 1.是 0.否
      form: this.$form.createForm(this, { name: "coordinated" }),
      loading: false,
      imageUrl: "",
      addQuestionvisible: false, // 新建问题弹框
      selectPapervisible: false, // 选择试卷
      selectCoursevisible: false, // 选择课程
      confirmLoading: false,
      questionform: {
        name: "", //"名称",
        context: "", //"内容"
      },
      questionrules: {
        name: [{ required: true, message: "请输入问题", trigger: "blur" }],
        context: [{ required: true, message: "请输入回答", trigger: "blur" }],
      },
			imageTypeAll: ['jpg','jpeg','png']
    };
  },
  // 事件处理器
  methods: {
    moment,
    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().subtract(1, "day");
    },
    disabledDateTime(dateTime){
       if (moment(dateTime).isSame(moment(new Date()), "day")) {
        return {
          disabledHours: () => range(0, moment(new Date()).hour()),
          disabledMinutes: () =>
            moment(dateTime).isSame(moment(new Date()), "hour")
              ? range(0, moment(new Date()).minute())
              : []
        };
      }
    },
    // 优惠价修改
    priceBlur() {
      if (this.form.getFieldsValue(["specialPrice"]).specialPrice === "") {
        this.isMarket = 0;
      }
    },
    // 常见问题功能
    delQuestion(e) {
      // 删除
      let index = e + (this.pageNumber - 1) * this.pageSize;
      this.problemJson.splice(index, 1);
      if (
        (this.pageNumber - 1) * this.pageSize <= this.problemJson.length &&
        this.pageNumber != 1
      ) {
        this.pageNumber -= 1;
      }
    },
    editQuestionItem(e) {
      // 编辑
      let index = e + (this.pageNumber - 1) * this.pageSize;
      this.addQuestionvisible = true;
      this.questionIndex = index;
      this.questionform = JSON.parse(JSON.stringify(this.problemJson[index]));
    },
    // 上移下移
    questionUp(e) {
      this.problemJson.splice(e - 1, 0, this.problemJson.splice(e, 1)[0]);
    },
    questionDown(e) {
      this.problemJson.splice(e + 1, 0, this.problemJson.splice(e, 1)[0]);
    },
    // 格式化数据，递归将空的children置为undefined
    formatData(data) {
      const that = this;
      if (!data) return false;
      data.forEach((element) => {
        if (element.children && element.children.length > 0) {
          that.formatData(element.children);
        } else {
          element.children = undefined;
        }
      });
      return data;
    },
    // 选择商品分类
    changeShopType(e) {
      // console.log(e)
      this.goodsCategory = e.join(',');
      // console.log(this.goodsCategory, this.goodsClassifi);
    },
    // 表格选择
    selectpaperItem(selectedRowKeys) {
      let arr = selectedRowKeys.pop();
      this.selectedpaperRowKeys = [arr];
    },
    // 对比选择内容若为取消选中清除对应内容
    mapCourseList(){
      if(this.selectedcourseList.length > this.selectedcourseRowKeys.length) {
        this.selectedcourseList.map((item,index)=>{
          if(this.selectedcourseRowKeys.indexOf(item.courseId) == -1) {
            this.selectedcourseList.splice(index,1);
          }
        })
      }
    },

    selectcourseItem(selectedRowKeys) {
      this.selectedcourseRowKeys = selectedRowKeys;
      // 对比数组长度 大于删除 小于新增
      if(this.selectedcourseList.length > this.selectedcourseRowKeys.length) {
        this.mapCourseList(); // 同步已选内容
      }else{
        let lastItem = selectedRowKeys.slice(-1); // 获取新增项id
        this.courseList.some(item=>{
          if(item.courseId == lastItem) {
            this.selectedcourseList.push(item);
            return true
          }
        })
      }
    },
    selectAllpaper(selectedRows) {
      this.selectedRows = selectedRows;
    },

    // 文件上传
    customRequest(fileData) {
      const name = fileData.filename // 名称前缀
      let obj = {}

      const bucket = 'ueditor/product/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          this.$set(this.pictureList, name, url);
          obj[name] = url
          this.form.setFieldsValue(obj);
        } else {
          this.$message.error("上传失败");
        }
      });
    },

    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
    },


    // 选择试卷
    selectPaper() {
      if (this.selectedpaperRowKeys.length) {
        let arr = this.paperList.filter((item) => {
          return item.paperId === this.selectedpaperRowKeys[0];
        });
        // console.log(arr[0]);
        this.paper.title = '';
        this.paperName = arr[0].paperName;
        this.totalScore = arr[0].totalScore;
        this.selectPapervisible = false;
      } else {
        this.$message.error("请先选择试卷");
      }
    },
    // 删除单项
    deleteItem(e) {
      this.selectedcourseList.splice(e,1);
      this.selectedcourseRowKeys.splice(e,1);
      this.enterselectedcourseList.splice(e,1);
      console.log(this.selectedcourseList,this.selectedcourseRowKeys,this.enterselectedcourseList)
    },
    // 取消选择课程
    cancelSelectCouse() {
      this.selectedcourseList = JSON.parse(JSON.stringify(this.enterselectedcourseList));
      this.selectedcourseRowKeys = this.enterselectedcourseList.map(item=>{
        return item.courseId;
      })
      this.selectCoursevisible = false;
    },
    // 选择课程
    selectCourse() {
      if (this.selectedcourseRowKeys.length) {
        this.enterselectedcourseList = JSON.parse(JSON.stringify(this.selectedcourseList));
        this.selectCoursevisible = false;
      } else {
        this.$message.error("请先选择课程");
      }
    },
    // 新建/编辑问题
    addQuestionOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.questionIndex !== "") {
            this.problemJson.splice(this.questionIndex, 1, this.questionform);
            this.questionIndex = "";
          } else {
            this.problemJson.push(this.questionform);
          }
          this.questionform = {
            name: "",
            context: "",
          };
          this.addQuestionvisible = false;
        } else {
          return false;
        }
      });
    },
    // 分页
    onPage(e) {
      this.pageNumber = e.current;
      this.pageSize = e.pageSize;
    },
    // 试卷列表分页
    onPaperPage(e) {
      this.paper.pageNumber = e.current;
      this.paper.pageSize = e.pageSize;
      this.getManageList();
    },
    // 课程列表分页
    onCoursePage(e) {
      this.course.pageNumber = e.current;
      this.course.pageSize = e.pageSize;
      this.getCourseList();
    },
    // 富文本内容有变化时触发   获取pc简介
    getPcContentwebDesc(e) {
      this.$set(this.text, "webDesc", e);
    },
    // getPcContentappDesc(e) {
    //   this.$set(this.text, "appDesc", e);
    // },
    getPcContentappGoodsDesc(e) {
      this.$set(this.text, "appGoodsDesc", e);
    },
    getPcContentwebGoodsDesc(e) {
      this.$set(this.text, "webGoodsDesc", e);
    },
    // 修改上架时间
    changelineTime(value, dateString) {
      // console.log("Selected Time: ", value);
      // console.log("Formatted Selected Time: ", dateString);
    },
    lineTimeOk(value) {
      // console.log("onOk: ", value);
    },
    // 修改原价 是否终端展示
    changeOldPrice(e) {
      this.isMarket = e.target.checked ? 1 : 0;
    },
    // 修改优惠价 是否终端展示
    changeNewPrice(e) {
      this.isSpecial = e.target.checked ? 1 : 0;
    },
    moment,
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          if (values.marketPrice < values.specialPrice) {
            return this.$message.warning(
              "优惠价为用户支付价格，不得设置大于原价"
            );
          }
          if (!this.selectedcourseRowKeys.length) {
            return this.$message.warning("请选择关联课程");
          } else if (!this.selectedpaperRowKeys.length) {
            return this.$message.warning("请选择关联试卷");
          }
          //  else if (values.isInstituBuy == 1) {
          //   if (!values.instituBuyNum) {
          //     return this.$message.warning("请输入机构购买最小人数");
          //   }
          // }
          if (values.isExam == 1) {
            if (!values.examNum) {
              return this.$message.warning("请输入考试次数");
            }
          }
          if (!this.text.webDesc) {
            return this.$message.warning("请输入模考简介(pc端)");
          } else if (!this.text.appDesc) {
            return this.$message.warning("请输入模考简介(移动端)");
          } 
          // else if (!this.text.appGoodsDesc) {
          //   return this.$message.warning("请输入详情介绍（app端）");
          // } else if (!this.text.webGoodsDesc) {
          //   return this.$message.warning("请输入详情介绍（pc端）");
          // } 
          // else if (!this.problemJson.length) {
          //   return this.$message.warning("请新建常见问题");
          // } 
          else if (values.isShelf == 2) {
            if (!values.shelfTime) {
              return this.$message.warning("请设置上架时间");
            }else if(new Date(values.shelfTime).getTime() < new Date().getTime()){
              return this.$message.warning("上架时间无法设置早于当前的时间");
            }
          }
          this.buttonloading = true;
          let _params = {};
          // 编辑
          if (this.configId) {
            _params["configId"] = this.configId; // 考试名称
          }
          _params["name"] = values.name; // 考试名称
          _params["appPicture"] = this.pictureList.appPicture; // app图片
          _params["appDetailPicture"] = this.pictureList.appDetailPicture; // app详情图片
          _params["webPicture"] = this.pictureList.webPicture; // web图片
          _params["webDetailPicture"] = this.pictureList.webDetailPicture; // web详情图片
          _params["editorPicture"] = this.pictureList.editorPicture; // app主播力荐
          _params["duration"] = values.duration; // 考试时长
          _params["courseIds"] = this.selectedcourseRowKeys.join(','); // 关联课程
          _params["paperId"] = this.selectedpaperRowKeys[0]; // 关联试卷
          _params["passingScore"] = values.passingScore; // 合格分数
          _params["goodsCategory"] = this.goodsCategory; // 商品分类
          _params["goodsClassifi"] = this.goodsClassifi; // 商品类别
          _params["marketPrice"] = values.marketPrice; // 市场价
          _params["isMarket"] = this.isMarket; // 市场价是否终端显示
          _params["specialPrice"] = values.specialPrice; // 优惠价
          _params["isParticipa"] = values.specialPrice > 0 ? 1 : 0; // 是否收费 1.是  0.否
          // _params['isSpecial'] = this.isSpecial; // 优惠价是否终端显示
          // _params["isInstituBuy"] = values.isInstituBuy; // 机构购买设置
          // _params["instituPrice"] = values.instituPrice; // 机构购买价格
          // _params["instituBuyNum"] = values.instituBuyNum; // 机构购买所需人数
          // _params["isCoupon"] = values.isCoupon; // 机构能否以兑换卷形式购买
          _params["isExam"] = values.isExam; // 考试次数设置
          _params["examNum"] = values.isExam ? values.examNum : ""; // 考试次数
          _params["webDesc"] = this.text.webDesc; // 模考简介pc
          _params["appDesc"] = this.text.appDesc; // 模考简介app
          _params["appGoodsDesc"] = this.text.appGoodsDesc; // 详情介绍（app端）
          _params["webGoodsDesc"] = this.text.webGoodsDesc; // 详情介绍（pc端）
          _params["problemJson"] = this.problemJson?JSON.stringify(this.problemJson):null; // 常见问题
          _params["isShelf"] = values.isShelf; // 上架方式
          _params["shelfTime"] = values.shelfTime; // 上架时间
          this.$ajax({
            url: this.configId
              ? "/hxclass-management/exam/mock/config/edit"
              : "/hxclass-management/exam/mock/config/save",
            method: this.configId ? "put" : "post",
            params: _params,
          }).then((res) => {
              this.buttonloading = false;
            if (res.code == 200 && res.success) {
              this.$message.success('成功');
              this.$router.go(-1);
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    handleSelectChange(value) {
      // console.log(value);
      this.form.setFieldsValue({
        note: `Hi, ${value === "male" ? "man" : "lady"}!`,
      });
    },
    selectExchange(value) {},
    handleChange(info) {
      if (info.file.status === "uploading") {
        this.loading = true;
        return;
      }
      if (info.file.status === "done") {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (imageUrl) => {
          this.imageUrl = imageUrl;
          this.loading = false;
        });
      }
    },
    // 上传图片
    beforeUpload(file) {
      if (!this.$regular.imgReg.reg.test(file.name)) {
        this.$message.warning(this.$regular.imgReg.msg);
        return false;
      }
      const type = file.type.split('/')[1]
      const index = this.imageTypeAll.indexOf(type?.toLowerCase())
      // console.log(index)
      if(index == -1){
        this.$message.error('上传图片格式不正确，请上传为jpg、jpeg、png格式后缀的图片。');
        return false
      }
    },
    // 获取商品分类列表
    getShopList() {
      this.$ajax({
        url: "/hxclass-management/product-category/manage/list",
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.shopTypeoptions = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 获取试卷列表
    getManageList() {
      this.$ajax({
        url: "/hxclass-management/exam/group/list",
        method: "get",
        params: {
          category: 2, // 组卷大类 1.考试 2.模拟考试
          courseId: '', // 课程id this.selectedcourseRowKeys[0]
          name: this.paper.title, // 组卷名称
          current: this.paper.pageNumber,
          size: this.paper.pageSize,
          status: 1
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.paperList = res.data.records;
          this.paper.total = res.data.total;
        }
      });
    },
    // 获取考试详情
    getExamDetail() {
      this.$ajax({
        url: "/hxclass-management/exam/mock/config/get",
        method: "get",
        params: {
          configId: this.configId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.form.setFieldsValue({ name: res.data.name }); // 考试姓名
          this.pictureList.appPicture = res.data.appPicture; // 移动端列表图
          this.form.setFieldsValue({ appPicture: res.data.appPicture });
          this.pictureList.appDetailPicture = res.data.appDetailPicture; // 移动端详情图
          this.form.setFieldsValue({
            appDetailPicture: res.data.appDetailPicture,
          });
          this.pictureList.webPicture = res.data.webPicture; // pc列表图
          this.form.setFieldsValue({ webPicture: res.data.webPicture });
          this.pictureList.webDetailPicture = res.data.webDetailPicture; // pc详情图
          this.form.setFieldsValue({
            webDetailPicture: res.data.webDetailPicture,
          });
          this.pictureList.editorPicture = res.data.editorPicture; // app主播力荐
          this.form.setFieldsValue({ editorPicture: res.data.editorPicture });
          this.form.setFieldsValue({ duration: res.data.duration }); // 时长
          // this.form.setFieldsValue({ courseId: res.data.courseId }); // 课程id
          // this.selectedcourseRowKeys.push(res.data.courseId);
          // 课程数据存储
          this.selectedcourseRowKeys = res.data.courseList.map(item=>{
            return item.courseId
          })
          this.selectedcourseList = JSON.parse(JSON.stringify(res.data.courseList));
          this.enterselectedcourseList = JSON.parse(JSON.stringify(res.data.courseList));

          this.form.setFieldsValue({ paperId: res.data.paperId }); // 试卷id
          this.selectedpaperRowKeys.push(res.data.paperId);
          // 试卷名称
          this.paperList.map((item, index) => {
            // console.log(item.paperId == res.data.paperId);
            if (item.paperId == res.data.paperId) {
              this.$set(this, "paperName", item.paperName);
              this.$set(this, "totalScore", item.totalScore);
              return;
            }
          });
          // 课程名称
          this.courseList.map((item, index) => {
            // console.log(item.courseId == res.data.courseId);
            if (item.courseId == res.data.courseId) {
              this.$set(this, "courseName", item.courseName);
              return;
            }
          });
          this.form.setFieldsValue({ passingScore: res.data.passingScore }); // 及格分数
          this.goodsCategory = res.data.goodsCategory; // 商品分类
          // this.goodsClassifi = res.data.goodsClassifi; // 商品类别
          this.form.setFieldsValue({
            goodsType: res.data.goodsCategory.split(','),
          }); // 商品分类
          this.form.setFieldsValue({ marketPrice: res.data.marketPrice }); // 市场价
          this.isMarket = res.data.isMarket; // 市场价是否在终端显示
          this.form.setFieldsValue({ specialPrice: res.data.specialPrice }); // 优惠价
          // this.isSpecial = res.data.isSpecial; // 优惠价是否在终端显示
          // this.form.setFieldsValue({ isInstituBuy: res.data.isInstituBuy }); // 是否允许机构购买
          // setTimeout(() => {
          //   this.form.setFieldsValue({ instituPrice: res.data.instituPrice }); // 机构价格
          //   this.form.setFieldsValue({ instituBuyNum: res.data.instituBuyNum }); // 需达到多少人可享受机构价
          // }, 0);
          // this.form.setFieldsValue({ isCoupon: res.data.isCoupon * 1 }); // 以兑换券形式购买 0.否 1.是
          this.form.setFieldsValue({ isExam: res.data.isExam }); // 考试次数设置
          this.form.setFieldsValue({ examNum: res.data.examNum }); // 考试次数
          this.form.setFieldsValue({ isShelf: res.data.isShelf }); // 是否上架
          setTimeout(()=>{
            this.form.setFieldsValue({ shelfTime: res.data.shelfTime }); // 上架时间
          },500)
          this.text.webDesc = res.data.webDesc; // 模考简介pc
          this.text.appDesc = res.data.appDesc; // 模考简介app
          this.text.appGoodsDesc = res.data.appGoodsDesc; // 详情介绍（app端）
          this.text.webGoodsDesc = res.data.webGoodsDesc; // 详情介绍（pc端）
          this.problemJson = [];
          res.data.problemList.map((item, index) => {
            this.problemJson.push({
              name: item.name, //"名称",
              context: item.context, //"内容"
            });
          });
        }
      });
    },
    // 获取课程列表
    getCourseList() {
      this.$ajax({
        url: "/hxclass-management/course/box",
        method: "get",
        params: {
          keyword: this.course.title,
          pageNum: this.course.pageNumber,
          pageSize: this.course.pageSize,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.course.total = res.data.total;
          this.courseList = res.data.records;
        } else {
          this.$message.error(res.message);
        }
      });
    },
  },
  // 生命周期-实例创建完成后调用
  async created() {
    await this.getManageList(); // 获取试卷列表
    await this.getCourseList(); // 获取课程列表
    this.getShopList(); // 获取商品分类列表
    if (this.$route.query.configId) {
      this.configId = this.$route.query.configId;
      await this.getExamDetail();
    }
    // 生成假数据
    for (let i = 1; i < 40; i++) {
      this.courseList.push({
        id: i,
        code1: "数据" + i,
        code2: "数据" + i,
      });
    }
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
/deep/.ant-radio-group{
  line-height: inherit;
}
.uploadImg {
  width: 86px;
  height: 86px;
}
.findPaper {
  display: flex;
  justify-content: space-between;
  .ant-input {
    width: 300px;
  }
}
.tips {
  margin-top: 5px;
  font-size: 12px;
  color: #ff3838;
}
.selectCourse {
  font-size: 14px;
  color: #2b6ed4;
  line-height: 40px;
  cursor: pointer;
}
.gardeTitle {
  font-size: 16px;
  color: #333333;
}
.selectitem {
  margin-bottom: 10px;
  display: flex;
  .name {
    font-size: 14px;
    color: #999999;
    line-height: 16px;
  }
  .del {
    margin-left: 19px;
    font-size: 14px;
    color: #2b6ed4;
    line-height: 16px;
    cursor: pointer;
  }
}
.selectitem:last-child{
  margin-bottom: 0;
}
.ant-row {
  position: relative;
  .addQuesbtn {
    position: absolute;
    top: -40px;
    left: 1000px;
  }
}
</style>
